import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'select2/dist/css/select2.min.css'


import $ from 'jquery';
import 'jquery'
import 'bootstrap'
import 'popper.js'
import '@fortawesome/fontawesome-free'
import './edenland-theme/js/bootstrap-datepicker'
import 'select2/dist/js/select2.min';

global.$ = global.jQuery = $;
global.validate = require('jquery-validation/dist/jquery.validate.min.js')
global.select2 = require('select2/dist/js/select2.min')
global.swal = require('sweetalert2')

global.validate = require('jquery-validation/dist/jquery.validate.min.js');

// start the Stimulus application
// import './bootstrap';